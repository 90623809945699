module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"withWebP":true,"quality":80,"disableBgImage":true,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rouverpa","short_name":"Rouverpa","description":"Rouverpa is een bedrijf dat al meer dan 30 jaar gespecialiseerd is in serviceverpakkingen en etiketten. Onze klanten bevinden zich in de bloemensector, chocoladesector, etc.","start_url":"/","background_color":"#F0EEEE","theme_color":"#FF243A","display":"browser","icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7eedcebf217957965a4afc6001d5c845"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","fr","nl"],"defaultLanguage":"nl","siteUrl":"https://rouverpa.be/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false},"pages":[{"matchPath":"/:lang?/gamma/categorieen/:slug","getLanguageFromPath":true},{"matchPath":"/:lang?/gamma/producten/:slug","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/:slug","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
